






import { defineComponent, computed } from '@vue/composition-api';
import { genChart, genDataId } from '../utils';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const dataId = genDataId();
    const { dataSet, dataLabels } = props.data;

    const hasDataSet = computed(() => dataSet.length > 0);
    const hasTableHeader = computed(() => Object.keys(dataLabels).length > 0);

    const chart = genChart({
      data: {
        columns: [
          ['data1', 30, 20, 50, 40, 60, 50],
          ['data2', 200, 130, 90, 240, 130, 220],
          ['data3', 300, 200, 160, 400, 250, 250],
          ['data4', 200, 130, 90, 240, 130, 220],
          ['data5', 130, 120, 150, 140, 160, 150],
          ['data6', 90, 70, 20, 50, 60, 120],
          ['data7', 283, 170, 275, 143, 220, 255],
        ],
        type: 'bar',
        types: {
          data3: 'spline',
          data4: 'line',
          data6: 'area',
          data7: 'step',
        },
        groups: [
          [
            'data1',
            'data2',
          ],
        ],
      },
      bindto: dataId,
    });
    return {
      hasDataSet,
      hasTableHeader,
      dataId,
    };
  },
});
